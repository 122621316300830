import "../sass/7-bundles/index.scss";

async function loadPreloader() {
  import('../scripts/preloader.js')
}

async function loadIndexAnim() {
  import('../scripts/indexAnim.js')
}

async function loadVideo() {
  import('../scripts/video.js')
}

async function loadSlider() {
  import('../scripts/slider.js')
}

async function loadWarning() {
  import('../scripts/warning.js')
}

window.addEventListener('load', async function loadModules() {
  await loadPreloader()
  await loadIndexAnim()
  await loadVideo()
  await loadSlider()
  await loadWarning()
})

